import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/src/app/[locale]/_home.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/app/[locale]/trends/TrendDescription.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/molecules/LeaguesPlaying/_leaguesPlaying.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/organisms/Table/Table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/organisms/TopLeaguesSection/topLeaguesSection.tsx");
